import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_BlueIris_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Using BlueIris v5 with your WQHD Camera",
  "path": "/Frequently_Asked_Question/WQHD_with_BlueIris_v5/",
  "dateChanged": "2024-04-11",
  "author": "Mike Polinowski",
  "excerpt": "Is there already an update or a description of how the 9420 can also be integrated into blue iris?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Using BlueIris v5 with your WQHD Camera' dateChanged='2024-04-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Is there already an update or a description of how the 9420 can also be integrated into blue iris?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_with_BlueIris_v5/' locationFR='/fr/Frequently_Asked_Question/WQHD_with_BlueIris_v5/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "using-blueiris-v5-with-your-wqhd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-blueiris-v5-with-your-wqhd-camera",
        "aria-label": "using blueiris v5 with your wqhd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using BlueIris v5 with your WQHD Camera`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-iobroker-with-your-wqhd-camera"
        }}>{`Using ioBroker with your WQHD Camera`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#add-your-camera"
            }}>{`Add your Camera`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#cgi-commands"
            }}>{`CGI Commands`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#livestream"
            }}>{`Livestream`}</a></li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`Q`}</strong>{`: The 9420 replaces my 9020, which could be `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/BlueIris_v5_preset_positions/"
      }}>{`perfectly integrated into blue iris`}</a>{` without any problems. After a bit of "trial and error", I managed to get the camera stream in blue iris. Unfortunately, however, no setting for the PTZ function works. Is there already an update or a description of how the 9420 can also be integrated into blue iris?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The PTZ commands for the new 2K+ WQHD series have been expanded compared to the older models - see `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/Features_Menu/PTZ/"
      }}>{`CGI documentation`}</a>{`. Those commands are not yet added to the BlueIris software.`}</p>
    <h2 {...{
      "id": "add-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#add-your-camera",
        "aria-label": "add your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add your Camera`}</h2>
    <p>{`There might be an update in the future - but you can already add those commands manually. Start by adding your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ad4112b597c83bc24988cf66a3c4e791/d73a9/BlueIrisv5_INSTAR_WQHD_PTZ_1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.956521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAoElEQVQI132KvQqCUABGr+jiqN1sb2gQI6ghKLBNTUHL2+9SRo/Q1GBDELU69LwnXIJAGg4HvvOJ8lVxe74pHxWX651EHUl3Z/w8x18oBpOAcHkgVgXxqsCP1vQGE4JwThAluMMp3f4YdzRD7U+IdLkiyzdkakuUpNi2jWVZyFYbaUscp4OUEsMwEEL8xfO82r+jruuNZ03TvjS12qZp8gEbrlUgMw0qlAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ad4112b597c83bc24988cf66a3c4e791/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_1.avif 230w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_1.avif 460w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_1.avif 920w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/e1c99/BlueIrisv5_INSTAR_WQHD_PTZ_1.avif 1380w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/91e3f/BlueIrisv5_INSTAR_WQHD_PTZ_1.avif 1591w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ad4112b597c83bc24988cf66a3c4e791/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_1.webp 230w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_1.webp 460w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_1.webp 920w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/445df/BlueIrisv5_INSTAR_WQHD_PTZ_1.webp 1380w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/d6e0a/BlueIrisv5_INSTAR_WQHD_PTZ_1.webp 1591w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ad4112b597c83bc24988cf66a3c4e791/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_1.png 230w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_1.png 460w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_1.png 920w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/b1001/BlueIrisv5_INSTAR_WQHD_PTZ_1.png 1380w", "/en/static/ad4112b597c83bc24988cf66a3c4e791/d73a9/BlueIrisv5_INSTAR_WQHD_PTZ_1.png 1591w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ad4112b597c83bc24988cf66a3c4e791/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_1.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Give your camera a name and select `}<strong parentName="p">{`Network IP`}</strong>{` camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0ef4fa7c54b46b9fd586798217bcd462/ddb6a/BlueIrisv5_INSTAR_WQHD_PTZ_2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB8UlEQVQoz43S30tTYRzH8cPZdnZOq1FLIowoMFnOMkex2nSZQyrq3pu86NfKNrCFlBjdBF101VVlpAjSD5uWf0k3sSCWzNrOOTnPdowu5ga6vWOzHzPMeuD7PPDwfF/P54FHGBi+R2TwLtdit7nUH+NyZJDozTucCfdy9kIv7f4uzl2McHXgFv3Xh+jtC9PU4qU71EOo5yStXj/NbT7ch/y0HQ4gXInGiMaGiN4Y5nw4SsPOXWzf0Yj7YBDPkVO0Hj3NPm83rj3tbGn04NrrpWG3B6fTydZtLiRlM1bZgShtQnE4EQRBoL5ku1RbH49O8Fk3ePvuA++Tn0jOaSRTGrrxjfsPHvFnX7WsVguC3W5HFEVE0YKiKEjSKjj1eoZKeRldy/B1MY+ZN1Azacory4yNT/wArGvAWm/9ZhX/CT5/GadQKJBOp8nlcpimiWEYrJTLjDwdr52xWCxrQJvNhiArjt831IHx6TcUi0USiQSpVIpsNlsDK5UKT0Y3AGvTOuCzF68olUq1hLquo2kaqqpS/lfCvz15Mj7NUqHA7OxHFrLzLJomppmnOjZMWA9WMUlaTTw5NUO5AtmFHKnMPHOZL6iqRmGpxMORsf8DZVn+ldDdcoDOrhD+juP4AkF8/iC+Yx0EOk/Q1Lx/3W9TBb8DV216ZZeOvR8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ef4fa7c54b46b9fd586798217bcd462/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_2.avif 230w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_2.avif 460w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_2.avif 920w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/e1c99/BlueIrisv5_INSTAR_WQHD_PTZ_2.avif 1380w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/67dcd/BlueIrisv5_INSTAR_WQHD_PTZ_2.avif 1592w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0ef4fa7c54b46b9fd586798217bcd462/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_2.webp 230w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_2.webp 460w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_2.webp 920w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/445df/BlueIrisv5_INSTAR_WQHD_PTZ_2.webp 1380w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/02aaf/BlueIrisv5_INSTAR_WQHD_PTZ_2.webp 1592w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ef4fa7c54b46b9fd586798217bcd462/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_2.png 230w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_2.png 460w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_2.png 920w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/b1001/BlueIrisv5_INSTAR_WQHD_PTZ_2.png 1380w", "/en/static/0ef4fa7c54b46b9fd586798217bcd462/ddb6a/BlueIrisv5_INSTAR_WQHD_PTZ_2.png 1592w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0ef4fa7c54b46b9fd586798217bcd462/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_2.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add your cameras IP address and your admin camera login. The camera `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/#rtsp-stream-livestream"
      }}>{`RTSP stream urls`}</a>{` are `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/livestream/11`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/livestream/12`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/livestream/13`}</code>{` for the 3 available resolution. For Full HD cameras those were just `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/11`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/13`}</code>{`. You can choose the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`11`}</code>{` stream as main and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`13`}</code>{` stream as sub stream:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/c655d/BlueIrisv5_INSTAR_WQHD_PTZ_3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACLElEQVQoz4WSW0tUURSAz21GKfoNvYQPXRAkopFExB7qIUjDbLQY07ykpWjaRShE8iGIghGlgh6CwIcsdbR6jeg3iMlo48wcHM9lzpxxRs+A8cU5aigVLVisvTd7f+tbsIX+oad033tMz90hWrvu0Hi9i6ttt6gO1VAdrOXoyTPUXWunqaOP5s5+Ll5p5nBJKacDASoqKjlWVs6RE6coKQ1wvKwc4cbNblo7e+noHqCuoYnK8/UE2+7T3jtCqPMRodtDBNsfUNvUR02oj7qWARo7Bqlv6SdQdQFRUlCKDyIqxRQfOIQgCAJ7cyryiVx+EzNjk81tsKan0dMZchsO2dwmRtpmOZZAM22ehV94byRJ9KpPkREUnw9ZllEUxes2HZlDW0uxEouhJpMk4ivEV2IYuo6uaywvLRH7sUzB2WR0bBsoK8o20OdHkCTJ24iiaygx+WGGTMZidXUVXdcxDAPTNEmnLSzLIm1ZaLrO1tZPwrtAWd4B+hB8/qJ9wOnIR1Q1STQaJekaJhIePJ/PUygUcByHfD6HG6PjL/8ESpK8D/h+KoJt2yRV1QNpmuaZuZaurZuuuRvhsb8C944sMjUzh53JMD8/Tzwe3wEYLC4u8n1hwTtTVRXHKfA8PP4/oMCXr9+87qlUimw2643njru+niWlm6wZJrqmeXdevX7zb6Cbfn8Rly43MDzyxPs+07Ofvbq7npicZWIywtuJdww+HKbq7LkdGfE38BctT+Cm+kbhFQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6445c06b8f7fbd5cdc372b4fddd74975/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_3.avif 230w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_3.avif 460w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_3.avif 920w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/e1c99/BlueIrisv5_INSTAR_WQHD_PTZ_3.avif 1380w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/636b7/BlueIrisv5_INSTAR_WQHD_PTZ_3.avif 1586w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6445c06b8f7fbd5cdc372b4fddd74975/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_3.webp 230w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_3.webp 460w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_3.webp 920w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/445df/BlueIrisv5_INSTAR_WQHD_PTZ_3.webp 1380w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/65131/BlueIrisv5_INSTAR_WQHD_PTZ_3.webp 1586w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6445c06b8f7fbd5cdc372b4fddd74975/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_3.png 230w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_3.png 460w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_3.png 920w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/b1001/BlueIrisv5_INSTAR_WQHD_PTZ_3.png 1380w", "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/c655d/BlueIrisv5_INSTAR_WQHD_PTZ_3.png 1586w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6445c06b8f7fbd5cdc372b4fddd74975/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_3.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Save those settings and enter the `}<strong parentName="p">{`PTZ Menu`}</strong>{`. Choose the `}<strong parentName="p">{`INSTAR (NEW)`}</strong>{` preset for Full HD Kameras and everything should be set. For 2K+ WQHD cameras we currently need to choose `}<strong parentName="p">{`Custom HTTP`}</strong>{` and click `}<strong parentName="p">{`Edit`}</strong>{` to manually add the new commands:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5f775a666d50b03a7f8631114ac0c690/4b701/BlueIrisv5_INSTAR_WQHD_PTZ_4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/ElEQVQoz43RS2gTURTG8ckkE2sSxZ34aKKxdKGlJVKjiQREKrpyIUXqY2VqMUotxLYbRVpQELWoK90oIuLGhSDiqoIo1fp25aIiIdrUSaZ5TNI080j7lwxYX1G8cDhwufd37scVTgxdpHfwDMcHhojG4kRj/UR7B4gc2ENkbyfrAhF27ztCV3ec/T397OqMsrIpwLbtHXTs2MmGQJim1iDNbSFaNoYRDh/to+dYnFjfIF0HD+FZuozW9ggj1+5x9sodzl29y/Dl25y6cIOT569zeuQmw5du4fM343K5cC72YHe6EKUGGlxLEARB4PcKhbcCc5h6GUMrw7wJVPm+VLXA6kbfH/ccDjuCQ5IQ7XarPB5rAu3BEOWKRjqdZmLiI1NfZTKKQiajkM1mSXyexLvGb50VRXEBlCQJ4eeNWoRa37Q5jGFWUVXVAiqVCoVCAUWZJp/P8SmRXABtNtuvoORcVBfUjSq5XBZN05mtzKLrOoZhWnEnp2S8vrX1QVG01wFD6IZJqVSyXqdpGjMzM1bXtApyZprGv4P1I+fVIoqiUFBVZFmmWCySTCZJpVIkkl/w+vz/Dwa3hK3f1HWNqmliGLW4BqZpMD9XJaNk/xX5B+h2u62+vqWN1+8/8PzlW168esfT8Tc8ePSM0cdjPBkb5/7DUZavWFUX/AZ++Km1NDyDfgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5f775a666d50b03a7f8631114ac0c690/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_4.avif 230w", "/en/static/5f775a666d50b03a7f8631114ac0c690/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_4.avif 460w", "/en/static/5f775a666d50b03a7f8631114ac0c690/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_4.avif 920w", "/en/static/5f775a666d50b03a7f8631114ac0c690/e1c99/BlueIrisv5_INSTAR_WQHD_PTZ_4.avif 1380w", "/en/static/5f775a666d50b03a7f8631114ac0c690/20f9c/BlueIrisv5_INSTAR_WQHD_PTZ_4.avif 1587w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5f775a666d50b03a7f8631114ac0c690/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_4.webp 230w", "/en/static/5f775a666d50b03a7f8631114ac0c690/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_4.webp 460w", "/en/static/5f775a666d50b03a7f8631114ac0c690/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_4.webp 920w", "/en/static/5f775a666d50b03a7f8631114ac0c690/445df/BlueIrisv5_INSTAR_WQHD_PTZ_4.webp 1380w", "/en/static/5f775a666d50b03a7f8631114ac0c690/d75e6/BlueIrisv5_INSTAR_WQHD_PTZ_4.webp 1587w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5f775a666d50b03a7f8631114ac0c690/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_4.png 230w", "/en/static/5f775a666d50b03a7f8631114ac0c690/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_4.png 460w", "/en/static/5f775a666d50b03a7f8631114ac0c690/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_4.png 920w", "/en/static/5f775a666d50b03a7f8631114ac0c690/b1001/BlueIrisv5_INSTAR_WQHD_PTZ_4.png 1380w", "/en/static/5f775a666d50b03a7f8631114ac0c690/4b701/BlueIrisv5_INSTAR_WQHD_PTZ_4.png 1587w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5f775a666d50b03a7f8631114ac0c690/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_4.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "cgi-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cgi-commands",
        "aria-label": "cgi commands permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CGI Commands`}</h2>
    <p>{`The following are the most important commands that you can add to control your camera through BlueIris interface. BlueIris also offers slots for image parameters like brightness and contrast - if you want to add them you can find them in our `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/Multimedia_Menu/Image/"
      }}>{`CGI documentation`}</a>{`.`}</p>
    <p><strong parentName="p">{`Note`}</strong>{`: that the following examples cannot be used by every camera model and are marked accordingly. For more details check out the FAQ `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/WQHD_Advanced_PTZ_Commands/"
      }}>{`2K+ WQHD Extended PTZ Command Set`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/926c342a28a3edb6f261d03df7fbe6f4/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAABKElEQVQY0zXMTUsCURSH8fn+EYWLJCSid8JNm6KsqK9QGcXkZM7cuXrfzh3NUlF4QqXFj3MWD/+kfnZHo/lA7fiWnZMW9dMW9ZO12tENtaMWG3tXq3/3/J7tw5u1g2u2Dq5Xd2lz/4pG85FEacNsMka85fWz5KlraPccL18W44X5fIbqW5bd9HdM8BZVKow1OO+xzhJEmE4nDGwgyXolzjnSNOXhzXLxLFy215T8sFgs6OQDPnsaZy3pRwdtHNbLivGBajRmMpuT9z1J9qWwZkBZan5Hkel3ZDaumIwiMQpVFJbNf6e1pqoiVYwMh0MkRkQiUQK5GpB0uooognMeFwQfhL4NPH8UpD1NUeS03zO6uWZYRUIIq5HloHGB16wgy/uoIqfTzfkD0iNkKxb4BNIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/926c342a28a3edb6f261d03df7fbe6f4/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.avif 230w", "/en/static/926c342a28a3edb6f261d03df7fbe6f4/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.avif 460w", "/en/static/926c342a28a3edb6f261d03df7fbe6f4/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.avif 920w", "/en/static/926c342a28a3edb6f261d03df7fbe6f4/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/926c342a28a3edb6f261d03df7fbe6f4/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.webp 230w", "/en/static/926c342a28a3edb6f261d03df7fbe6f4/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.webp 460w", "/en/static/926c342a28a3edb6f261d03df7fbe6f4/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.webp 920w", "/en/static/926c342a28a3edb6f261d03df7fbe6f4/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/926c342a28a3edb6f261d03df7fbe6f4/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.png 230w", "/en/static/926c342a28a3edb6f261d03df7fbe6f4/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.png 460w", "/en/static/926c342a28a3edb6f261d03df7fbe6f4/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.png 920w", "/en/static/926c342a28a3edb6f261d03df7fbe6f4/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/926c342a28a3edb6f261d03df7fbe6f4/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_5_6.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Move Left`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&left=10`}</code>{` (only for indoor cameras e.g. IN-8415 2K+ WQHD)`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&left=0&timeout=1`}</code>{` (for all cameras e.g. IN-8415 2K+ WQHD, IN-9420 2K+ WQHD)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Move Right`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&right=10`}</code>{` (only for indoor cameras e.g. IN-8415 2K+ WQHD)`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&right=0&timeout=1`}</code>{` (for all cameras e.g. IN-8415 2K+ WQHD, IN-9420 2K+ WQHD)`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Here I am setting the number of steps per button press to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10`}</code>{` you can adjust them to your needs. It is also possible to `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/Features_Menu/PTZ/"
      }}>{`use a timeout in seconds instead of number of steps`}</a>{` if that is more suitable for your usecase.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b80dcf0a81537c0a428f8a94372cb1d1/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAABDUlEQVQY02XOyUrDUBSA4bw/gmDdiIKCzUK6dKG0oq58A4UmnZPcMffepJYODvySpgji4ufA4eNworObR857T3TiBzrdAZ1u/7eT6/5+d3R5x2k84KL33Jp48M8eX90T374Qlc6zXi2xRiGkwFh7yOC8Z7fbMs8Vi1yxXb9jjSbPc7Qxe9dMHwKb7QZlPZHUFq0UwyRFmhLjAqb0WBdYrtZ8fH2TzgSjWYHRiiQdIXWJKVvX1LjN7pNMlkSF1EghEEJQVxUhBKqqwvuA95668qTTjGSSobVqXd26uq733zUueEcmNJFQhuA9zrn2WAgo63gdZ0xzRZEveBtOGM8K6ir8cdIcXCbJFnMm84If/UpmbYLok5QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b80dcf0a81537c0a428f8a94372cb1d1/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.avif 230w", "/en/static/b80dcf0a81537c0a428f8a94372cb1d1/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.avif 460w", "/en/static/b80dcf0a81537c0a428f8a94372cb1d1/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.avif 920w", "/en/static/b80dcf0a81537c0a428f8a94372cb1d1/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b80dcf0a81537c0a428f8a94372cb1d1/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.webp 230w", "/en/static/b80dcf0a81537c0a428f8a94372cb1d1/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.webp 460w", "/en/static/b80dcf0a81537c0a428f8a94372cb1d1/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.webp 920w", "/en/static/b80dcf0a81537c0a428f8a94372cb1d1/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b80dcf0a81537c0a428f8a94372cb1d1/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.png 230w", "/en/static/b80dcf0a81537c0a428f8a94372cb1d1/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.png 460w", "/en/static/b80dcf0a81537c0a428f8a94372cb1d1/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.png 920w", "/en/static/b80dcf0a81537c0a428f8a94372cb1d1/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b80dcf0a81537c0a428f8a94372cb1d1/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_7_8.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Move Up`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&up=10`}</code>{` (only for indoor cameras e.g. IN-8415 2K+ WQHD)`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&up=0&timeout=1`}</code>{` (for all cameras e.g. IN-8415 2K+ WQHD, IN-9420 2K+ WQHD)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Move Down`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&down=10`}</code>{` (only for indoor cameras e.g. IN-8415 2K+ WQHD)`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&down=0&timeout=1`}</code>{` (for all cameras e.g. IN-8415 2K+ WQHD, IN-9420 2K+ WQHD)`}</li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/298100149b10d85f31d5493e7b16ff0c/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAABF0lEQVQY01WQOU/DQBBG/fdpqJLQRECDoQA6JIoEAQ0VuCAn9oK99h621+tdOwdESB/yInEUT6OZefpGGm94foujy3scnN04Bqfjf3Sz/eNrDPwRDi/u0PfHjp4/Qu/kl74/wt7wCt5rwtBYA845KKUQQkBK4aqQEtvNCgtCMQsTtI11XhzHYIw5p+s5F9isWwRTAu+NcuhK4SUMkYkCuaohlf6m1HjffWJOGJaEwtQaYUSQycLtHEpDFBU2HzsE8wQeiTPkuXQXjbEwxsBYi9p0GKzaBrMwdpRFjjTLnGOthbXNj7tetXiaRF1gF1SjqiporVHXGqXSmJIUUcLBWYrgeYlFRN1r1B+vUBozkiJMmPMeHif4AntoZEQWTJzwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/298100149b10d85f31d5493e7b16ff0c/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.avif 230w", "/en/static/298100149b10d85f31d5493e7b16ff0c/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.avif 460w", "/en/static/298100149b10d85f31d5493e7b16ff0c/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.avif 920w", "/en/static/298100149b10d85f31d5493e7b16ff0c/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/298100149b10d85f31d5493e7b16ff0c/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.webp 230w", "/en/static/298100149b10d85f31d5493e7b16ff0c/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.webp 460w", "/en/static/298100149b10d85f31d5493e7b16ff0c/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.webp 920w", "/en/static/298100149b10d85f31d5493e7b16ff0c/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/298100149b10d85f31d5493e7b16ff0c/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.png 230w", "/en/static/298100149b10d85f31d5493e7b16ff0c/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.png 460w", "/en/static/298100149b10d85f31d5493e7b16ff0c/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.png 920w", "/en/static/298100149b10d85f31d5493e7b16ff0c/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/298100149b10d85f31d5493e7b16ff0c/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_9_10.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Up Left`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&up=10&left=10`}</code>{` (only for indoor cameras e.g. IN-8415 2K+ WQHD)`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&up=0&timeout=1&left=0&timeout=1`}</code>{` (for all cameras e.g. IN-8415 2K+ WQHD, IN-9420 2K+ WQHD)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Up Right`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&up=10&right=10`}</code>{` (only for indoor cameras e.g. IN-8415 2K+ WQHD)`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&up=0&timeout=1&right=0&timeout=1`}</code>{` (for all cameras e.g. IN-8415 2K+ WQHD, IN-9420 2K+ WQHD)`}</li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7e6b12dd2efdd3c54a254b7514317c46/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAABEElEQVQY003Pu0/CUBiG8f79Dm4gJmrCApJo4mRcAC8TccELIUBLLT2nFGjPhZvi8hgOIg6/7Um+9/POru65uH6gWG04hWqdQuWgWKlzXL6jVGtQvnmiVGvuXDY4+ee01uTo/BYviCRaa4QQhOEHsRDIJEFI6SwXc7r+yJlbg5SSMAxdn4zHrt1arxa0Xvp4kUhReYYfBMh0xiTTpJlikimSScZ6800vTOgPBVrtuynpTDl/3deG57ch3jCSpOnYXbTWYqzF2jnm13K5oDuI6PoRs+nEdcaYQ2cs2lg+V0ta7R5eOEowRpPnOUoptFJMM0XHj/GjBClGtN/79ILYvZztO71b1wliBttRScxj65UfbNVkJ3N10PQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e6b12dd2efdd3c54a254b7514317c46/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.avif 230w", "/en/static/7e6b12dd2efdd3c54a254b7514317c46/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.avif 460w", "/en/static/7e6b12dd2efdd3c54a254b7514317c46/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.avif 920w", "/en/static/7e6b12dd2efdd3c54a254b7514317c46/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7e6b12dd2efdd3c54a254b7514317c46/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.webp 230w", "/en/static/7e6b12dd2efdd3c54a254b7514317c46/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.webp 460w", "/en/static/7e6b12dd2efdd3c54a254b7514317c46/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.webp 920w", "/en/static/7e6b12dd2efdd3c54a254b7514317c46/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e6b12dd2efdd3c54a254b7514317c46/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.png 230w", "/en/static/7e6b12dd2efdd3c54a254b7514317c46/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.png 460w", "/en/static/7e6b12dd2efdd3c54a254b7514317c46/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.png 920w", "/en/static/7e6b12dd2efdd3c54a254b7514317c46/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7e6b12dd2efdd3c54a254b7514317c46/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_11_12.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Down Left`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&down=10&left=10`}</code>{` (only for indoor cameras e.g. IN-8415 2K+ WQHD)`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&down=0&timeout=1&left=0&timeout=1`}</code>{` (for all cameras e.g. IN-8415 2K+ WQHD, IN-9420 2K+ WQHD)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Down Right`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&down=10&right=10`}</code>{` (only for indoor cameras e.g. IN-8415 2K+ WQHD)`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&down=0&timeout=1&right=0&timeout=1`}</code>{` (for all cameras e.g. IN-8415 2K+ WQHD, IN-9420 2K+ WQHD)`}</li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bd90fda76a7d65e6806f101a095c3e75/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAABD0lEQVQY02XLzUrDQBhG4dw/uNGNVMGFLUh1Iy4Ef2gX4h1UsDW1nUnmN5OZpKkujyQFXbg4i5fv+bKz6xln0xknk6dD40eOxw+/9fvo4p7zmzmXty+MpnNG09m/Tq+emdy9khXKsmsbrNFshUAWBcZatDZoY+m6HZvCIEvD977FOUOpFNa5od467+m6Dm0DmVSWynuWqxXKOFxV40KNDxFbBbr9F2tpWQuFd47l6gNtq+He294YH0jtDqErsq3UGKMpS0VMiRgTKTXUMQ21bUO+KYasNSilSCkSY6RpehcPP7FGlIZMFGYYIYQBpRjxVc0il+RCU0jB2/uaT6FoUvxzKWJ9YJEXrGXvtuQbyQ8HNGZAT8LcFwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd90fda76a7d65e6806f101a095c3e75/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.avif 230w", "/en/static/bd90fda76a7d65e6806f101a095c3e75/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.avif 460w", "/en/static/bd90fda76a7d65e6806f101a095c3e75/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.avif 920w", "/en/static/bd90fda76a7d65e6806f101a095c3e75/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bd90fda76a7d65e6806f101a095c3e75/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.webp 230w", "/en/static/bd90fda76a7d65e6806f101a095c3e75/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.webp 460w", "/en/static/bd90fda76a7d65e6806f101a095c3e75/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.webp 920w", "/en/static/bd90fda76a7d65e6806f101a095c3e75/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd90fda76a7d65e6806f101a095c3e75/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.png 230w", "/en/static/bd90fda76a7d65e6806f101a095c3e75/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.png 460w", "/en/static/bd90fda76a7d65e6806f101a095c3e75/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.png 920w", "/en/static/bd90fda76a7d65e6806f101a095c3e75/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bd90fda76a7d65e6806f101a095c3e75/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_13_14.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Down Right`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&down=10&right=10`}</code>{` (only for indoor cameras e.g. IN-8415 2K+ WQHD)`}</li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&down=0&timeout=1&right=0&timeout=1`}</code>{` (for all cameras e.g. IN-8415 2K+ WQHD, IN-9420 2K+ WQHD)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Home`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=gotoRelPosition&pan=50&tilt=50`}</code>{` (only for indoor cameras e.g. IN-8415 2K+ WQHD)`}</li>
        </ul>
      </li>
    </ul>
    <p>{`You can use a relative position to define your home position. The value range here is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{`-`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`100`}</code>{`. The example shown above will center your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/19d603b7997f990ed62e25ff69847748/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAABF0lEQVQY01WPSUvDUBhF8/83Lqxg1Y2ipVUXrkSwWsGNewWxbULmNyTvvaQOiRNHEovg4nAX3+FePm97fMXe6Q2D0ZTBUcflH5uHv7mxf8HuyYyDs1uGkxk7xzOGk+t/bI2mjM/v8DKheX1ZoZUkzTKEVCitkUqhi5KmbYgzRZor2rdnykL1XucUZYkuNMZa2rZBFhYvziRaK57mC4Qu0aVFG0dhHKaq+fj6xo8FQZT3o/PFEqHW3hpbrWjeP4lFgRcmOVIIsjynrisq5/p0zvXUlSOIMvwwRUlJvvb6W11jrcN2rjXEqcSLUkHlLMaYXuoKdWl4WCYEiSBNYh7nAX6Y4azt3zTGYq0lVyX3ixg/zomisB/9AZ3/Zq0A2xZXAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19d603b7997f990ed62e25ff69847748/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.avif 230w", "/en/static/19d603b7997f990ed62e25ff69847748/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.avif 460w", "/en/static/19d603b7997f990ed62e25ff69847748/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.avif 920w", "/en/static/19d603b7997f990ed62e25ff69847748/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/19d603b7997f990ed62e25ff69847748/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.webp 230w", "/en/static/19d603b7997f990ed62e25ff69847748/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.webp 460w", "/en/static/19d603b7997f990ed62e25ff69847748/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.webp 920w", "/en/static/19d603b7997f990ed62e25ff69847748/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19d603b7997f990ed62e25ff69847748/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.png 230w", "/en/static/19d603b7997f990ed62e25ff69847748/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.png 460w", "/en/static/19d603b7997f990ed62e25ff69847748/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.png 920w", "/en/static/19d603b7997f990ed62e25ff69847748/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/19d603b7997f990ed62e25ff69847748/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_15_16.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Zoom In`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&zoomin=10`}</code></li>
        </ul>
      </li>
      <li parentName="ul">{`Zoom Out`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&zoomout=10`}</code></li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/15784cb5f03386cc48c931d0af988098/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAABHElEQVQY02WPSUvDUBhF8/9BxC4souAIVVy4dCoi7gTBjba0qa15bV/e/BIHNER6pNGNuDjcxXe43C/ZOLpi5+SG9uEV7U73D+udLsv7yvYpa3vnbB1f0zq4pLV/8Y/V3TM2j69Jcm2pP9+J3qK0wjlLiAHvXZOLRc1E5IyzOXX1jncGpRTOO5xz2F+/qj5RNpCMhcRozWCQkhuHduEH67GhoKq/eEwF/ZHAO8tgmLIcYXxsPOMC8eWNj6pGSEsyEXOMVuRSUpYFRRGbjDE2vJaRfvpML33GGY2UkiLGhrIsCTESQiAGj5gpkn6aobRhJhVSGXJtEHPNXW/C42jK03jC7X2PpVcWEW0s1vmmZPnRw2hKmklEljF8yvgGEFxlZhbBWwIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15784cb5f03386cc48c931d0af988098/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.avif 230w", "/en/static/15784cb5f03386cc48c931d0af988098/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.avif 460w", "/en/static/15784cb5f03386cc48c931d0af988098/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.avif 920w", "/en/static/15784cb5f03386cc48c931d0af988098/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/15784cb5f03386cc48c931d0af988098/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.webp 230w", "/en/static/15784cb5f03386cc48c931d0af988098/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.webp 460w", "/en/static/15784cb5f03386cc48c931d0af988098/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.webp 920w", "/en/static/15784cb5f03386cc48c931d0af988098/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15784cb5f03386cc48c931d0af988098/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.png 230w", "/en/static/15784cb5f03386cc48c931d0af988098/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.png 460w", "/en/static/15784cb5f03386cc48c931d0af988098/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.png 920w", "/en/static/15784cb5f03386cc48c931d0af988098/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/15784cb5f03386cc48c931d0af988098/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_17_18.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Focus Near`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&focusin=10`}</code></li>
        </ul>
      </li>
      <li parentName="ul">{`Focus Far`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=ptzmove&focusout=10`}</code></li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/258e474f87ed3dd8f2a3a0daa847fec6/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA+0lEQVQY023OTUvDQBSF4fx/3Ih1pyhIu1FwKdguFH+CSjFNLJ1pJjOTj5lJ0rp8JUNFKS4O58J94N7k4vaJMZPZgsn0J/OYs+mc89mCk6uH2Jd3z3/Mrxvn05tHru9fSLSt2Q0dldUIKSmUwliLNib2fr/jU5SsheJr12P/dRX90KNMTVKUFmsNabpCmQpTt5iqwR66GwaWuWS13lJXlnSVUR67usWFnk1hSWShKUsVL4bg8c4RQsA5h3Oevgt85CLGGo1SCu+PnPcE7xCFJtkqg3MtTdPE5Qht3fCWSXJZUmwFr8ssftgFH117cLpqeM8luVBIsSFbS74BUcBliwBYMCoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/258e474f87ed3dd8f2a3a0daa847fec6/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.avif 230w", "/en/static/258e474f87ed3dd8f2a3a0daa847fec6/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.avif 460w", "/en/static/258e474f87ed3dd8f2a3a0daa847fec6/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.avif 920w", "/en/static/258e474f87ed3dd8f2a3a0daa847fec6/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/258e474f87ed3dd8f2a3a0daa847fec6/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.webp 230w", "/en/static/258e474f87ed3dd8f2a3a0daa847fec6/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.webp 460w", "/en/static/258e474f87ed3dd8f2a3a0daa847fec6/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.webp 920w", "/en/static/258e474f87ed3dd8f2a3a0daa847fec6/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/258e474f87ed3dd8f2a3a0daa847fec6/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.png 230w", "/en/static/258e474f87ed3dd8f2a3a0daa847fec6/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.png 460w", "/en/static/258e474f87ed3dd8f2a3a0daa847fec6/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.png 920w", "/en/static/258e474f87ed3dd8f2a3a0daa847fec6/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/258e474f87ed3dd8f2a3a0daa847fec6/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_19_20.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Preset Position 1`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=getptzpreset&act=goto&index=1`}</code></li>
        </ul>
      </li>
      <li parentName="ul">{`Preset Position 2`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=getptzpreset&act=goto&index=2`}</code></li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4f545df7820a6cb97c91121fe13232a2/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAABC0lEQVQY01WPTUsDMRCG9//fFAU/8CBFocWD51paxH8gKthttU022U2y+VjX0uMjTeuhh4d3YJ5hZorbxxd2XI1mXA6nR1wMp1yPZpwMxpzfT7h5eM55dvfP04EJp4Nx7heNcWw3Pb61VEqh6xrXtljncM6x3W5YVw1rWWfPWYOQkkppjLU0xmCso//t0aalEMrgnOVzXqKNw7QB4zz2kOmnZ77SLFeK1lnm5WLvuWMvdj2ibinWVY1papTWpBRJMdJ1iRAjMUb6LlF+y4w1DUrp7KSU6LouO3FXp4ioGoqVrAnB5ze9D4Sw3/i2kPkyKQWvH4t84W7Ie08IkeB9/uZ9KVkKjRRryi/BHzAGZgRhrKS/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4f545df7820a6cb97c91121fe13232a2/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.avif 230w", "/en/static/4f545df7820a6cb97c91121fe13232a2/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.avif 460w", "/en/static/4f545df7820a6cb97c91121fe13232a2/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.avif 920w", "/en/static/4f545df7820a6cb97c91121fe13232a2/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4f545df7820a6cb97c91121fe13232a2/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.webp 230w", "/en/static/4f545df7820a6cb97c91121fe13232a2/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.webp 460w", "/en/static/4f545df7820a6cb97c91121fe13232a2/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.webp 920w", "/en/static/4f545df7820a6cb97c91121fe13232a2/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4f545df7820a6cb97c91121fe13232a2/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.png 230w", "/en/static/4f545df7820a6cb97c91121fe13232a2/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.png 460w", "/en/static/4f545df7820a6cb97c91121fe13232a2/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.png 920w", "/en/static/4f545df7820a6cb97c91121fe13232a2/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4f545df7820a6cb97c91121fe13232a2/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_21_22.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Preset Position 1`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=getptzpreset&act=goto&index=3`}</code></li>
        </ul>
      </li>
      <li parentName="ul">{`Preset Position 2`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=getptzpreset&act=goto&index=4`}</code></li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8ec04bce842cc55b1f085b4d2a178b68/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAABGklEQVQY0z2PzUvDQBBH8/+fFStYUUFRsBTxqpVW8e5BrDZpPjabSbPdzSbR85Os4OHxg5k3zEx0cffC5f0r09mK09kq5MntkuntMuRYO7h64Phmwfn8mcnN0z9H14vA5HrB4dUjZ/NnoqKs6TqPVBVpllMoRSWCrgQtwjAMrBPFR5zTdx6tNWmaUhQF1ehojYjQeU+maiJV7TCmYbOJkZ2h2buAsX/5/fPDeqv52pY4uydOtujaIMYhjaU2FjEW63vSsiHKVEUtf5u872hbj+86XOsDw9CzjnM+NjnNrqYsdeh3I30fZkZGL1NClJeCbx3WWtq2pXWOndnznigSJeiy4O19w2eiwsuj59zo78N1wSsqVJHxGWf8AhP+ZbbNCsTHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ec04bce842cc55b1f085b4d2a178b68/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.avif 230w", "/en/static/8ec04bce842cc55b1f085b4d2a178b68/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.avif 460w", "/en/static/8ec04bce842cc55b1f085b4d2a178b68/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.avif 920w", "/en/static/8ec04bce842cc55b1f085b4d2a178b68/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8ec04bce842cc55b1f085b4d2a178b68/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.webp 230w", "/en/static/8ec04bce842cc55b1f085b4d2a178b68/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.webp 460w", "/en/static/8ec04bce842cc55b1f085b4d2a178b68/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.webp 920w", "/en/static/8ec04bce842cc55b1f085b4d2a178b68/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ec04bce842cc55b1f085b4d2a178b68/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.png 230w", "/en/static/8ec04bce842cc55b1f085b4d2a178b68/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.png 460w", "/en/static/8ec04bce842cc55b1f085b4d2a178b68/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.png 920w", "/en/static/8ec04bce842cc55b1f085b4d2a178b68/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8ec04bce842cc55b1f085b4d2a178b68/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_23_24.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Preset Position 1`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=getptzpreset&act=goto&index=5`}</code></li>
        </ul>
      </li>
      <li parentName="ul">{`Preset Position 2`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=getptzpreset&act=goto&index=6`}</code></li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/780bb0b93d83bc9056c677eaf1ff6919/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAABEUlEQVQY02XPT0vDQBCH4Xz/m9JeighW7KEi6LVErNAvIPinbZK2ySbZzTbJbhLbHl/JUgTx8GOG4RmY8a4fXhk/LRhN54ymL/9ydT/nYjxjOPHp7XDyfI7P4M5nMPFdf3k74+ZxgZcrzfG7Za8VIk3JckmhNaooXD2djoS7jGCbOlcoRZwkpFnmjFTKua5ryVWJl2QKrQtW6wCpS4qypthX6HPtDgeWUcpqI6hKzToIyYv9H9fHth1Clng7kaOkJMtzGmuxxtA0DbUxGGPpWssyjPkKYvdF7/q5MQZ7dlVdu93+OC9OJdbUVFXlkKlrlC55jwQbIclSwdtnwCoStI39ddYad+FHJIiSHJHEhFvBDwR0Zd8MCiTzAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/780bb0b93d83bc9056c677eaf1ff6919/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.avif 230w", "/en/static/780bb0b93d83bc9056c677eaf1ff6919/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.avif 460w", "/en/static/780bb0b93d83bc9056c677eaf1ff6919/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.avif 920w", "/en/static/780bb0b93d83bc9056c677eaf1ff6919/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/780bb0b93d83bc9056c677eaf1ff6919/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.webp 230w", "/en/static/780bb0b93d83bc9056c677eaf1ff6919/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.webp 460w", "/en/static/780bb0b93d83bc9056c677eaf1ff6919/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.webp 920w", "/en/static/780bb0b93d83bc9056c677eaf1ff6919/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/780bb0b93d83bc9056c677eaf1ff6919/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.png 230w", "/en/static/780bb0b93d83bc9056c677eaf1ff6919/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.png 460w", "/en/static/780bb0b93d83bc9056c677eaf1ff6919/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.png 920w", "/en/static/780bb0b93d83bc9056c677eaf1ff6919/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/780bb0b93d83bc9056c677eaf1ff6919/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_25_26.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Preset Position 1`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=getptzpreset&act=goto&index=7`}</code></li>
        </ul>
      </li>
      <li parentName="ul">{`Preset Position 2`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=getptzpreset&act=goto&index=8`}</code></li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9e2f677371404ad49fa16578e0d565b5/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAABCUlEQVQY023PMUvDQBiH8Xz/zUms4NAu2uJQR0GrUD+AoNU2iTa55C655HKXWJpW0UeMgkgdnuGF3/B/vaPTa/rjKb3RFb3h5E8HJ5ccjibsDc67e3A2pTfcdV/tH1/QH9/gyUyzXjUUOiMWAqkUudZkeU5RGrbbDcEyJVymbNYv6H9caQxt25KoAi9OM7TOmS98lC7JC9OlywrXrHh7/2Dmx8xDQVloFn6Ayn9dXprOtdtXnpMcbykkmVKkUlI7h7OWuq6prKWqLI2zPAYRD37UfSGlxDm742xleI4lXpQonK0wxmDtN9Sl4S4QPImMNBHczvxuYVO7zlU/LisM96EgjBWJiFmEEZ8Cb2a5PxjWtwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e2f677371404ad49fa16578e0d565b5/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.avif 230w", "/en/static/9e2f677371404ad49fa16578e0d565b5/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.avif 460w", "/en/static/9e2f677371404ad49fa16578e0d565b5/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.avif 920w", "/en/static/9e2f677371404ad49fa16578e0d565b5/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9e2f677371404ad49fa16578e0d565b5/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.webp 230w", "/en/static/9e2f677371404ad49fa16578e0d565b5/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.webp 460w", "/en/static/9e2f677371404ad49fa16578e0d565b5/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.webp 920w", "/en/static/9e2f677371404ad49fa16578e0d565b5/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e2f677371404ad49fa16578e0d565b5/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.png 230w", "/en/static/9e2f677371404ad49fa16578e0d565b5/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.png 460w", "/en/static/9e2f677371404ad49fa16578e0d565b5/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.png 920w", "/en/static/9e2f677371404ad49fa16578e0d565b5/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9e2f677371404ad49fa16578e0d565b5/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_27_28.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`IR LEDs On`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=setinfrared&infraredstat=1`}</code></li>
        </ul>
      </li>
      <li parentName="ul">{`IR LEDs Off`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=setinfrared&infraredstat=0`}</code></li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c4b99f8e6b7b26091098990ece46718/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_29.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAvUlEQVQY043MO0sDQRRA4fz/xiaV2AhqoaWNoBArf4GND9C4O6+7M3NnDMsm2bBHFLSUPXDab3F8ueLk6p7lxR3L899v/z46veHs+oHxcOC7aZr4r4WXSL/5RMTz0TS0xhBE8CHQxUjf9+RSGbb7eaBxgoTA0/MLLkR8lwhdQmJG64ZhNyKpzAcb43HOYq2l1kIpSq0VVUW1kHNCusSwmwm2NqCaSSn9IEUViYnH14a31rNev9Max3Y/zgK/ABWkenlkaef3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c4b99f8e6b7b26091098990ece46718/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_29.avif 230w", "/en/static/4c4b99f8e6b7b26091098990ece46718/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_29.avif 460w", "/en/static/4c4b99f8e6b7b26091098990ece46718/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_29.avif 920w", "/en/static/4c4b99f8e6b7b26091098990ece46718/433d8/BlueIrisv5_INSTAR_WQHD_PTZ_29.avif 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c4b99f8e6b7b26091098990ece46718/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_29.webp 230w", "/en/static/4c4b99f8e6b7b26091098990ece46718/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_29.webp 460w", "/en/static/4c4b99f8e6b7b26091098990ece46718/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_29.webp 920w", "/en/static/4c4b99f8e6b7b26091098990ece46718/2baf0/BlueIrisv5_INSTAR_WQHD_PTZ_29.webp 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c4b99f8e6b7b26091098990ece46718/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_29.png 230w", "/en/static/4c4b99f8e6b7b26091098990ece46718/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_29.png 460w", "/en/static/4c4b99f8e6b7b26091098990ece46718/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_29.png 920w", "/en/static/4c4b99f8e6b7b26091098990ece46718/c83ae/BlueIrisv5_INSTAR_WQHD_PTZ_29.png 1180w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c4b99f8e6b7b26091098990ece46718/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_29.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`IR LEDs Auto`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`/param.cgi?cmd=setinfrared&infraredstat=2`}</code></li>
        </ul>
      </li>
    </ul>
    <p>{`Here I am using the `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/Features_Menu/IR_Nightvision/"
      }}>{`Infrared Status`}</a>{` command to to turn the IR LEDs on/off or to activate the automatic mode. Alternatively, you can also use the `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/Features_Menu/IR_Nightvision/#paramcgicmdgetinfrared"
      }}>{`Set Infrared Command`}</a>{` to switch your camera from day to night mode with: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`infraredcutstat: IRcut filter - 0 (off), 1 (on), 2 (auto)`}</code>{`.`}</p>
    <h2 {...{
      "id": "livestream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#livestream",
        "aria-label": "livestream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Livestream`}</h2>
    <p>{`Save your settings and close the settings menu to access your cameras live video. Hover your mouse cursor over the live video to the left, righ, top, bottom as well as the 4 corners and the cursor should change to an arrow - click your left mouse button to move your PTZ camera in that direction:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/561c328dce7f46196f2bf049cc188068/2f183/BlueIrisv5_INSTAR_WQHD_PTZ_30.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC00lEQVQoz1WRS08bBxRGpy5GLBEE1w7YM+PB2J7xa5yZ8RjbmHhsDw9jYkgoQU1UYkqaBIcGqrYJy9KESkkWVSoqZVOlyy7aqo9VW6lSQnZIRFmxT39A+gNOVUtN08Un3U/36uhIV/jk0y/Y2rnD9q1drly7ya2de1zo3OBc5ypOY4WZZpsPP77L5vYeH928T33mXaycy1JrhWn3HIY1Tdp0MXMNTKuK0F7f4GL7KmvvdyjZNcxkCT1aRBpKoMomVqqCGraIBDNoioVbXqRabFLQHabKLRrVJYqGy2Suwbg5iSAIAv9meOQkcTVKeFRiyDfAcDBA9lSGWDzCiaEB7LzF4tkWVs5AlEJYOZPTlTKliQLJlIaqxRE8njfxeDz09fXh9/vRNA1FUfD7A0iSjKYlaLUW2Nv7nBtb22xufkCxVCIajZHJ6N2dW3exc3a3vzL0er34fD4CgQChYJDAW0PIkkg0otC+tMrzZ0esLC+xvtbGyOooYZl4NIJbr5K3/4FlsG37/8CBwUFCoSDKWJxBOc3IqIaSylOZavLtL3+wen2HbMmlMreMe+Y859e3mH37EuNlB9Mw0PXXDHt7e+nv72eh1eKdCxcJKjG0lI5hF7ly7Tr7D7/GmW4yf3YZx50lpqUw8iXalzdYba/h1utEIpH/gD09PYiiSLlcxnEcEppKVte5/dkuz46OeHrwhIMnj/n9t1/5+acf+eH777hze5f79+7S6WxQq9WQJOk1oNeLLMuEw2GisRjpdIa4qnJw8JTDw0MePPiS/f2vePHiT16+/Ivj42MePfqG5vw8up4lJIrdZ74Cet4QEIMjyFKIeGysa5hJJ6lVHeabc1xef687N2anuz1nGSTUePc2mVCRxCCyLCH4gmOcGB5FHEuTtsrE9QLaqQmS5iR63sEouoyfniU77lCoNJicWqAys8hErdlNwZnDLE2hGRPoGYu/AWnQg52g1udGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/561c328dce7f46196f2bf049cc188068/e4706/BlueIrisv5_INSTAR_WQHD_PTZ_30.avif 230w", "/en/static/561c328dce7f46196f2bf049cc188068/d1af7/BlueIrisv5_INSTAR_WQHD_PTZ_30.avif 460w", "/en/static/561c328dce7f46196f2bf049cc188068/7f308/BlueIrisv5_INSTAR_WQHD_PTZ_30.avif 920w", "/en/static/561c328dce7f46196f2bf049cc188068/e1c99/BlueIrisv5_INSTAR_WQHD_PTZ_30.avif 1380w", "/en/static/561c328dce7f46196f2bf049cc188068/3b5c2/BlueIrisv5_INSTAR_WQHD_PTZ_30.avif 1589w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/561c328dce7f46196f2bf049cc188068/a0b58/BlueIrisv5_INSTAR_WQHD_PTZ_30.webp 230w", "/en/static/561c328dce7f46196f2bf049cc188068/bc10c/BlueIrisv5_INSTAR_WQHD_PTZ_30.webp 460w", "/en/static/561c328dce7f46196f2bf049cc188068/966d8/BlueIrisv5_INSTAR_WQHD_PTZ_30.webp 920w", "/en/static/561c328dce7f46196f2bf049cc188068/445df/BlueIrisv5_INSTAR_WQHD_PTZ_30.webp 1380w", "/en/static/561c328dce7f46196f2bf049cc188068/5b9d2/BlueIrisv5_INSTAR_WQHD_PTZ_30.webp 1589w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/561c328dce7f46196f2bf049cc188068/81c8e/BlueIrisv5_INSTAR_WQHD_PTZ_30.png 230w", "/en/static/561c328dce7f46196f2bf049cc188068/08a84/BlueIrisv5_INSTAR_WQHD_PTZ_30.png 460w", "/en/static/561c328dce7f46196f2bf049cc188068/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_30.png 920w", "/en/static/561c328dce7f46196f2bf049cc188068/b1001/BlueIrisv5_INSTAR_WQHD_PTZ_30.png 1380w", "/en/static/561c328dce7f46196f2bf049cc188068/2f183/BlueIrisv5_INSTAR_WQHD_PTZ_30.png 1589w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/561c328dce7f46196f2bf049cc188068/c0255/BlueIrisv5_INSTAR_WQHD_PTZ_30.png",
              "alt": "Using ioBroker with your WQHD Camera",
              "title": "Using ioBroker with your WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      